<template>
	<div>
		<div class="page-main hc bsbb">
			<div class="left-window hc fl h100 bsbb">
				<div class="menu-scroll-area">
					<el-menu popper-effect="light" active-text-color="#FFF" @select="selectMenu" router background-color="#222653" text-color="#FFF" default-active="/indexMng/mat" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
						<el-menu-item popper-class="sub-popper-class" index="/index/mat">
							<el-icon>
								<component :is="'HomeFilled'"></component>
							</el-icon>
							<template v-slot:title>
								<div style="margin-left: 5px;">素材</div>
							</template>
						</el-menu-item>
						<el-menu-item popper-class="sub-popper-class" index="/index/equip">
							<el-icon>
								<component :is="'Platform'"></component>
							</el-icon>
							<template v-slot:title>
								<div style="margin-left: 5px;">设备</div>
							</template>
						</el-menu-item>
					</el-menu>
				</div>
			</div>
			<div class="right-window bsbb" style="">
				<div class="bottom-window fl w100 bsbb" style="background: #FFF;">
					<router-view v-slot="{ Component }" class="" style="width: 100%;height: calc(100%);">
						<component :is="Component" />
					</router-view>
				</div>
			</div>

		</div>
	</div>
</template>

<script setup>

</script>


<style scoped lang="scss">
	.page-main {
		width: 100%;
		height: 100vh;
	}

	.info-area {
		font-size: 13px;
		margin-left: 20px;

		.text {
			color: #9FA8C9;
		}

		.info {
			color: var(--el-color-primary);
			font-weight: 600;
			margin-left: 5px;
		}
	}

	.menu-sub {
		font-size: 12px;
		color: #FFF;
		padding: 10px 15px;
	}

	.sub-popper-class {
		padding: 5px !important;
		border-radius: 18px;
		background: #409EFF !important;
	}

	:deep .el-popper {
		padding: 5px !important;
		border-radius: 18px;
		background: #409EFF !important;
	}

	.el-submenu__title:hover {
		border-radius: 50% !important;
		/* 使用 !important 来确保覆盖默认样式 */
	}

	.el-menu {
		border: 0 !important;
		padding-top: 20px;
	}

	.el-menu-vertical-demo {
		/* margin: 10px; */
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 180px;
		min-height: 400px;
		border: none;
		text-align: left;
	}

	.el-menu-item {
		border-radius: 15px;
		margin-bottom: 5px;
		height: 50px;
		/* transition: all 0.3s; */
		/* width: 90%;
		margin: 0 5%; */
	}

	.el-menu-item:hover {
		background: #F4FAFF;
		color: #CCC;
	}

	.el-menu-item.is-active {
		background: #4B69FF;
		border-radius: 15px;
		/* animation: 9s bgMove linear infinite; */
	}

	:deep .el-sub-menu__title {
		border-radius: 15px;
		margin-bottom: 5px;
		height: 50px;
	}

	.home {
		height: calc(100vh);
	}

	.left-window {
		background: #222653;
		/* border-right: solid 0.1px #EEE; */
		/* background: linear-gradient(102deg, rgba(#EAF0FD, .5), rgba(#E6E7FD, .5)); */
		display: flex;
		flex-direction: column;
		user-select: none;

		.menu-scroll-area {
			flex: 1;
			height: 0;
			padding: 0 10px;
		}
	}

	.logo-cont {
		background: #FFF;
		width: 40px;
		height: 40px;
		border-radius: 999px;
		margin-top: 15px;
		/* margin-bottom: 20px; */
	}

	.right-window {
		flex: 1;
		height: calc(100vh);
		border-radius: 15px 0 0 15px;

		.top-window {
			-webkit-app-drag: drag;
			user-select: none;
			background: #FFFFFF;

			height: 55px;
			padding: 0 0px 0 0;

			.top-inner-top {
				height: 20px;
			}

			.nav-bar {
				height: 40px;
			}

			.top-inner {
				padding: 0 10px 0 0;
				height: 55px;
				/* border-bottom: $border-common; */
			}

			.left {
				flex: 1;

				.fold-icon {
					color: #FFF;
					height: 35px;
					width: 40px;
					font-size: 20px;

					.iconfont {
						font-size: 20px;
					}
				}
			}

			.mid {

				.search-input {
					margin-right: 5px;
					width: 200px;
				}

				.right-icon {
					color: #000;
					font-size: 18px;
					width: 35px;
					height: 35px;
				}

				.user-log {
					margin-left: 10px;
				}
			}

			.right {

				.y-line {
					width: 1.5px;
					height: 20px;
					background: #cfcfcf;
					margin-left: 15px;
					margin-right: 5px;
				}

				.right-icon {
					color: #000;
					width: 35px;
					height: 35px;
					cursor: pointer;

					i {
						font-size: 14px;
					}

					i:hover {
						color: #DDD;
					}
				}
			}
		}

		.bottom-window {
			height: calc(100% - 55px);
			border-radius: 15px 0 0 0;
			background: #FFF;
		}
	}
</style>