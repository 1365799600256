import mainRequest from '@/libs/request/mainRequest.js';

function getRootMap(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/getRootMap',
		data: data
	})
}

function getAdminMap(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/getAdminMap',
		data: data
	})
}

function getMaterialPage(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/getMaterialPage',
		data: data
	})
}

function insertMaterial(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/insertMaterial',
		data: data
	})
}

function editMaterial(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/editMaterial',
		data: data
	})
}

function delMaterial(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/delMaterial',
		data: data
	})
}





function getAccountPage(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/getAccountPage',
		data: data
	})
}

function insertAccount(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/insertAccount',
		data: data
	})
} 

function editAccount(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/editAccount',
		data: data
	})
}

function delAccount(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/delAccount',
		data: data
	})
}

export default {
	getRootMap,
	getAdminMap,
	getMaterialPage,
	insertMaterial,
	editMaterial,
	delMaterial,
	getAccountPage,
	insertAccount,
	editAccount,
	delAccount
}