<template>
	<div class="">
		<div class="mat-main w100 bsbb">
			<div class="wh100 mat-content">
				<div class="title-area w100 hc">
					<el-button type="primary" round @click="clickBatch">批量</el-button>
					<input accept=".xls,.xlsx" style="width: 15px;opacity: 0;" ref="inputFile" id="inputF" type="file" @change="handleFileChange">
					<el-space>
						<el-input clearable @keyup.enter="searchMat" v-model="pageData.search" style="width: 150px;" placeholder="搜索"></el-input>

						<el-select clearable @change="searchMat" v-model="pageData.type" placeholder="类型" style="min-width: 120px;">
							<el-option label="直登" :value="0" />
							<el-option label="正版备份包" :value="1" />
							<el-option label="商城备份包" :value="2" />
							<el-option label="搜索备份包" :value="6" />
							<el-option label="失败" :value="3" />
							<el-option label="正版-爱思" :value="4" />
							<el-option label="商城-爱思" :value="5" />
							<el-option label="搜索-爱思" :value="7" />
							<el-option label="操作中" :value="8" />
							<el-option label="数据" :value="9" />
							<el-option label="港版" :value="10" />
							<el-option label="极速" :value="11" />
						</el-select>
						<el-select clearable @change="searchMat" v-model="pageData.loginType" placeholder="登录状态" style="width: 120px;">
							<el-option label="未登录" :value="0" />
							<el-option label="成功商城" :value="1" />
							<el-option label="成功搜索" :value="4" />
							<el-option label="双上" :value="2" />
							<el-option label="失败" :value="3" />
						</el-select>
						<el-select clearable @change="searchMat" v-model="pageData.loginFailStatus" placeholder="失败原因" style="width: 120px;">
							<el-option label="正常" :value="0" />
							<el-option label="频繁" :value="1" />
							<el-option label="操作失败" :value="3" />
							<el-option label="实名号" :value="4" />
							<el-option label="二要素" :value="5" />
						</el-select>
						<el-select clearable @change="searchMat" v-model="pageData.accountStatus" placeholder="账号状态" style="width: 120px">
							<el-option label="三无" :value="0" />
							<el-option label="违规中" :value="1" />
							<el-option label="违规恢复" :value="2" />
							<el-option label="封禁" :value="3" />
							<el-option label="未知" :value="9" />
						</el-select>
						<el-select clearable @change="searchMat" v-model="pageData.status" placeholder="售出" style="width: 120px;">
							<el-option label="已售" :value="1"></el-option>
							<el-option label="未售" :value="0"></el-option>
						</el-select>
						<el-button type="primary" icon="Refresh" round @click="searchMat">刷新</el-button>
					</el-space>
					<div style="flex:1">
					</div>
					<el-button type="primary" round @click="clickAdd">新建</el-button>
				</div>
				<div class="table-area w100" style="position: relative;">
					<el-table v-loading="loading" class="fl" :data="matList" style="height: 100%;width: 100%;position: absolute;top: 0;left: 0" empty-text="无">
						<el-table-column type="selection" width="40" />
						<el-table-column type="index" label="序号" width="80" />
						<el-table-column prop="areaCode" label="区" width="80" />
						<el-table-column prop="phone" label="号" width="120" />
						<el-table-column prop="account" label="账号" width="120" />
						<el-table-column prop="fansNum" label="粉丝数" width="80" />
						<el-table-column prop="account" label="注册时间" width="120">
							<template #default="scope">
								{{getRegistDate(scope.row.registerTime)}}
							</template>
						</el-table-column>
						<el-table-column prop="account" label="备份时间" width="120">
							<template #default="scope">
								{{scope.row.backTime ? timestampToDateTime(scope.row.backTime) :'-'}}
							</template>
						</el-table-column>
						<el-table-column prop="account" label="账号类型" width="100">
							<template #default="scope" style="">
								<el-tag type="success" v-if="scope.row.type == 0">直登</el-tag>
								<el-tag type="primary" v-if="scope.row.type == 1">正版备份</el-tag>
								<el-tag type="warning" v-if="scope.row.type == 2">商城备份</el-tag>
								<el-tag type="warning" v-if="scope.row.type == 6">搜索备份</el-tag>
								<el-tag type="danger" v-if="scope.row.type == 3">失败</el-tag>
								<el-tag type="primary" v-if="scope.row.type == 4">正版-爱思</el-tag>
								<el-tag type="warning" v-if="scope.row.type == 5">商城-爱思</el-tag>
								<el-tag type="warning" v-if="scope.row.type == 7">搜索-爱思</el-tag>
								<el-tag type="info" v-if="scope.row.type == 8">操作中</el-tag>
								<el-tag type="info" v-if="scope.row.type == 9">数据</el-tag>
								<el-tag type="primary" v-if="scope.row.type == 10">港版</el-tag>
								<el-tag type="primary" v-if="scope.row.type == 11">极速</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="account" label="违规状态" width="100">
							<template #default="scope">
								<el-tag type="success" v-if="scope.row.accountStatus == 0">三无</el-tag>
								<el-tag type="danger" v-if="scope.row.accountStatus == 1">违规中</el-tag>
								<el-tag type="warning" v-if="scope.row.accountStatus == 2">违规恢复</el-tag>
								<el-tag type="danger" v-if="scope.row.accountStatus == 3">封禁</el-tag>
								<el-tag type="info" v-if="scope.row.accountStatus == 9">未知</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="account" label="登录状态" width="100">
							<template #default="scope">
								<el-tag type="info" v-if="scope.row.loginType == 0">未登录</el-tag>
								<el-tag type="primary" v-if="scope.row.loginType == 1">成功商城</el-tag>
								<el-tag type="primary" v-if="scope.row.loginType == 4">成功搜索</el-tag>
								<el-tag type="success" v-if="scope.row.loginType == 2">双上</el-tag>
								<el-tag type="danger" v-if="scope.row.loginType == 3">失败</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="account" label="失败原因" width="100">
							<template #default="scope">
								<el-tag type="success" v-if="scope.row.loginFailStatus == 0">正常</el-tag>
								<el-tag type="info" v-if="scope.row.loginFailStatus == 1">频繁</el-tag>
								<el-tag type="info" v-if="scope.row.loginFailStatus == 2">繁忙</el-tag>
								<el-tag type="danger" v-if="scope.row.loginFailStatus == 3">操作失败</el-tag>
								<el-tag type="danger" v-if="scope.row.loginFailStatus == 4">实名号</el-tag>
								<el-tag type="danger" v-if="scope.row.loginFailStatus == 5">二要素</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="fansNum" label="粉丝数" width="80" />
						<el-table-column prop="remark" show-overflow-tooltip label="备注" min-width="200" />
						<el-table-column prop="account" label="出售" width="100">
							<template #default="scope">
								<el-tag type="info" v-if="scope.row.status == 0">未出售</el-tag>
								<el-tag type="success" v-if="scope.row.status == 1">已出售</el-tag>
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="240">
							<template #default="scope">
								<el-button size="small" @click="editRow(scope)" style="border-radius: 5px;border:none;" type="primary" :icon="'EditPen'" circle plain></el-button>
								<el-button size="small" @click="removeRow(scope)" style="border-radius: 5px;border:none;" type="primary" :icon="'Delete'" circle plain></el-button>
								<el-button size="small" :disabled="!scope.row.smsUrl" @click="messageRow(scope)" style="border-radius: 5px;border:none;" type="primary" :icon="'Message'" circle plain></el-button>
								<el-popover placement="right" :width="120" trigger="click">
									<template #reference>
										<el-button @click="createQrcode(scope)" size="small" style="border-radius: 5px;border:none;" type="primary" :icon="'FullScreen'" circle plain></el-button>
									</template>
									<div class="qrArea w100">
										<el-image v-if="scope.row.qrUrl" class="wh100" :src="scope.row.qrUrl"></el-image>
									</div>
								</el-popover>
								<el-button size="small" @click="copyText(scope)" style="border-radius: 5px;border:none;" type="primary" :icon="'CopyDocument'" circle plain></el-button>
								<el-button size="small" @click="downloadTxtFile(scope)" style="border-radius: 5px;border:none;" type="primary" :icon="'Document'" circle plain></el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="jc w100" style="height: 50px;">
					<el-pagination v-model:current-page="pageData.pageNum" v-model:page-size="pageData.pageSize" :page-sizes="[20,50,100,200,1000]" :small="false" :background="false" layout="sizes, prev, pager, next, jumper" :total="pageTotal" @size-change="handleSizeChange"
						@current-change="handleCurrentChange" />
				</div>
			</div>
		</div>

		<el-dialog align-center v-model="dialogVisible" :title="'编辑'" width="700px">
			<div class="form-area w100 bsbb" style="padding: 0 20px;">
				<el-form class="el-form w100" style="" require-asterisk-position="left" ref="formRef" :model="formData">
					<el-form-item prop="rootId" :label-width="'100px'" class="user-form-item" label="rootId">
						<el-input maxlength="10" v-model="formData.rootId" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="nickname" :label-width="'100px'" class="user-form-item" label="号码">
						<el-input v-model="formData.phone" type="number" style="max-width: 600px" placeholder="号码" class="input-with-select">
							<template #prepend>
								<el-select v-model="formData.areaCode" placeholder="区号" style="width: 85px">
									<el-option label="+1" value="+1" />
									<el-option label="+852" value="+852" />
									<el-option label="+86" value="+86" />
								</el-select>
							</template>
						</el-input>
					</el-form-item>

					<el-form-item prop="nickname" :label-width="'100px'" class="user-form-item" label="账号链接">
						<el-input v-model="formData.smsUrl" maxlength="999" style="max-width: 600px" placeholder="账号链接" class="input-with-select">
						</el-input>
					</el-form-item>

					<el-form-item prop="nickname" :label-width="'100px'" class="user-form-item" label="账号信息">
						<el-space>
							<el-input v-model="formData.account" style="width: 200px;" placeholder="账号" class="input-with-select">
							</el-input>
							<el-select v-model="formData.accountStatus" placeholder="账号状态" style="width: 125px">
								<el-option label="三无" :value="0" />
								<el-option label="违规中" :value="1" />
								<el-option label="违规恢复" :value="2" />
								<el-option label="封禁" :value="3" />
								<el-option label="未知" :value="9" />
							</el-select>
							<el-input v-model="formData.fansNum" type="number" style="max-width: 600px" placeholder="粉丝数" class="input-with-select">
							</el-input>
						</el-space>
					</el-form-item>

					<el-form-item prop="nickname" :label-width="'100px'" class="user-form-item" label="类型">
						<el-space>
							<el-date-picker style="width: 236px;" v-model="formData.registerTime" type="date" placeholder="注册时间" />
							<el-select v-model="formData.type" placeholder="类型" style="min-width: 236px;">
								<el-option label="直登" :value="0" />
								<el-option label="正版备份包" :value="1" />
								<el-option label="商城备份包" :value="2" />
								<el-option label="搜索备份包" :value="6" />
								<el-option label="失败" :value="3" />
								<el-option label="正版-爱思" :value="4" />
								<el-option label="商城-爱思" :value="5" />
								<el-option label="搜索-爱思" :value="7" />
								<el-option label="操作中" :value="8" />
								<el-option label="数据" :value="9" />
								<el-option label="港版" :value="10" />
								<el-option label="极速" :value="11" />
							</el-select>
						</el-space>
					</el-form-item>

					<el-form-item prop="nickname" :label-width="'100px'" class="user-form-item" label="登录状态">
						<el-space>
							<el-select v-model="formData.loginType" placeholder="登录状态" style="min-width: 236px;">
								<el-option label="未登录" :value="0" />
								<el-option label="成功商城" :value="1" />
								<el-option label="成功搜索" :value="4" />
								<el-option label="双上" :value="2" />
								<el-option label="失败" :value="3" />
							</el-select>
							<el-select v-model="formData.loginFailStatus" placeholder="失败原因" style="min-width: 236px;">
								<el-option label="正常" :value="0" />
								<el-option label="频繁" :value="1" />
								<el-option label="操作失败" :value="3" />
								<el-option label="实名号" :value="4" />
								<el-option label="二要素" :value="5" />
							</el-select>
						</el-space>
					</el-form-item>

					<el-form-item prop="nickname" :label-width="'100px'" class="user-form-item" label="备份状态">
						<el-space>
							<el-input maxlength="999" style="width: 300px;" placeholder="备份地址" v-model="formData.backUrl" autocomplete="off" />
							<el-date-picker v-model="formData.backTime" style="width: 170px;" type="date" placeholder="备份时间" />
						</el-space>
					</el-form-item>


					<el-form-item prop="rootId" :label-width="'100px'" class="user-form-item" label="备注">
						<el-input type="textarea" rows="1" maxlength="999" v-model="formData.remark" autocomplete="off" />
					</el-form-item>

					<el-form-item prop="rootId" :label-width="'100px'" class="user-form-item" label="管理">
						<el-space>
							<el-input disabled placeholder="管理ID" style="width: 206px;" maxlength="20" v-model="formData.adminId" autocomplete="off" />
							<div>设备</div>
							<el-input disabled placeholder="设备ID" style="width: 206px;" maxlength="20" v-model="formData.deviceId" autocomplete="off" />
						</el-space>
					</el-form-item>

					<el-form-item prop="email" :label-width="'100px'" class="user-form-item" label="状态">
						<el-space>
							<el-select class="" style="width: 170px;" v-model="formData.status">
								<el-option label="已售" :value="1"></el-option>
								<el-option label="未售" :value="0"></el-option>
							</el-select>
							<el-input type="number" style="width: 170px;" placeholder="售价" maxlength="20" v-model="formData.sellAmount" autocomplete="off" />
							<el-date-picker v-model="formData.sellTime" style="width: 122px;" type="datetime" placeholder="售出" />
						</el-space>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="submitInfoForm">确认</el-button>
				</div>
			</template>
		</el-dialog>


		<el-dialog align-center v-model="dialogUpVisible" :title="'批量'" width="80%">
			<div class="form-area w100 bsbb" style="padding: 0 20px;">
				<el-table :data="batchList" style="height: 60vh;" empty-text="无">
					<el-table-column type="selection" width="40" />
					<el-table-column type="index" label="序号" width="80" />
					<el-table-column prop="name" label="区号" min-width="80">
						<template #default="scope">
							<el-input v-model="scope.row.areaCode"></el-input>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="号码" min-width="150">
						<template #default="scope">
							<el-input v-model="scope.row.phone"></el-input>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="链接" min-width="150">
						<template #default="scope">
							<el-input maxlength="999" v-model="scope.row.smsUrl"></el-input>
						</template>
					</el-table-column>

					<el-table-column prop="name" label="备注" width="240">
						<template #default="scope">
							<el-input maxlength="999" v-model="scope.row.remark"></el-input>
						</template>
					</el-table-column>

					<el-table-column prop="industry" label="保存状态" width="120">
						<template #default="scope">
							<el-tag v-if="scope.row.saveStatus == 0" type="info">未开始</el-tag>
							<el-tag v-if="scope.row.saveStatus == 1" type="warning">保存中</el-tag>
							<el-tag v-if="scope.row.saveStatus == 2" type="success">已完成</el-tag>
							<el-tag v-if="scope.row.saveStatus == 3" type="danger">异常</el-tag>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="80">
						<template #default="scope">
							<el-button size="small" @click="removeRowUp(scope)" style="border-radius: 5px;border:none;" type="primary" :icon="'Delete'" circle plain></el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogUpVisible = false">取消</el-button>
					<el-button type="primary" :loading="uploading" @click="submitUpForm">开始</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		inject,
		onMounted
	} from 'vue';

	import QRCode from 'qrcode';

	const axios = require('axios');

	const downUrl = 'https://file.douyin.bet/api/materials/download/'

	import localCache from '../../libs/utils/localCache.js';

	const mainApi = inject('mainRequest');

	import * as XLSX from 'xlsx';

	const inputFile = ref(null);

	setTimeout(() => {
		console.log(inputFile)
	}, 1000)

	const uploaderData = ref({
		token: ''
	})

	const dialogUpVisible = ref(false);

	const dialogVisible = ref(false);

	const loading = ref(false);

	const customColors = ref([{
			color: '#f56c6c',
			percentage: 20
		},
		{
			color: '#e6a23c',
			percentage: 40
		},
		{
			color: '#6f7ad3',
			percentage: 60
		},
		{
			color: '#1989fa',
			percentage: 80
		},
		{
			color: '#5cb87a',
			percentage: 100
		}
	]);

	const formData = ref({
		"rootId": "0",
		"areaCode": "", //区号
		"phone": "", //号
		"account": "", //账号
		"smsUrl": "", //号链
		"fansNum": 0, //粉丝数
		"registerTime": '', //注册时间
		"accountStatus": 9, //0-三无 1-违规中 2-违规恢复 9-未知
		"status": 0, //0-未出售 1-已出售'
		"type": 9, //0-直登 1-正版备份包 2-商城备份包  3-失败 5-处理中 9-数据
		"remark": "", //备注
		"adminId": "", //创建人
		"backUrl": "", //备份
		"deviceId": "", //设备
		"backTime": '', //备份时间
		"loginType": 0, //登录状态 0-未登录 1-成功商城 2-双上 3-失败
		"loginFailStatus": 0, //登录失败原因 0-正常 1-频繁 2-繁忙 3-操作失败 4-实名号 5-二要素
		"sellTime": '', //售出
		"sellAmount": "" //售出
	})

	const searchMat = () => {
		pageData.value.pageNum = 1;
		getAccountPage()
	}

	const uploading = ref(false);

	const sortArr = ref([{
		label: '创建时间正序',
		value: 1
	}, {
		label: '创建时间倒叙',
		value: 2
	}, {
		label: '最后使用时间正序',
		value: 3
	}, {
		label: '最后使用时间倒叙',
		value: 4
	}, {
		label: '通过次数正序',
		value: 5
	}, {
		label: '通过次数倒叙',
		value: 6
	}, {
		label: '未通过次数正序',
		value: 7
	}, {
		label: '未通过次数倒叙',
		value: 8
	}, {
		label: '最后修改时间正序',
		value: 9
	}, {
		label: '最后修改时间倒叙',
		value: 0
	}])

	const resetFormData = () => {
		formData.value = {
			"rootId": "0",
			"areaCode": "", //区号
			"phone": "", //号
			"account": "", //账号
			"smsUrl": "", //号链
			"fansNum": 0, //粉丝数
			"registerTime": '', //注册时间
			"accountStatus": 9, //0-三无 1-违规中 2-违规恢复 9-未知
			"status": 0, //0-未出售 1-已出售'
			"type": 9, //0-直登 1-正版备份包 2-商城备份包  3-失败 5-处理中 9-数据
			"remark": "", //备注
			"adminId": "", //创建人
			"backUrl": "", //备份
			"deviceId": "", //设备
			"backTime": '', //备份时间
			"loginType": 0, //登录状态 0-未登录 1-成功商城 2-双上 3-失败
			"loginFailStatus": 0, //登录失败原因 0-正常 1-频繁 2-繁忙 3-操作失败 4-实名号 5-二要素
			"sellTime": '', //售出
			"sellAmount": "", //售出
			"accountId": ""
		};
	}

	const batchList = ref([]);

	const submitUpForm = async () => {
		if (!batchList.value.length) {
			return;
		}
		uploading.value = true;
		for (let i in batchList.value) {
			const item = batchList.value[i];
			await asyncSaveMat(item, i)
		}
		uploading.value = false;
		ElMessage({
			message: '执行结束',
			type: 'success'
		})
		getAccountPage();
	}

	const asyncSaveMat = async (data, index) => {
		batchList.value[index].saveStatus = 1;
		return new Promise((resolve, reject) => {
			mainApi.admin.insertAccount({
				...data
			}).then(res => {
				if (res.flag) {
					batchList.value[index].saveStatus = 2;
				} else {
					batchList.value[index].saveStatus = 3;
					batchList.value[index].errorMsg = res.errorMsg;
				}
				resolve();
			})
		})
	}

	const upItem = async (file, index, status, progress) => {
		batchList.value[index][status] = 1;
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			// 通过文件路径读取文件，并添加到FormData中
			formData.append('file', file);
			// 设置请求头，告诉服务器这是一个表单数据
			axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
			// 发送POST请求
			axios.post('https://file.douyin.bet/api/materials/upload', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: progressEvent => {
						// 获取上传进度，progressEvent.loaded是已上传的数据，progressEvent.total是总数据量
						const uploadPercent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
						batchList.value[index][progress] = uploadPercent;
					},
				})
			.then(res => {
				console.log(res.status, res.data.success)
				if (res.status == 200 && res.data.success == true) {
					batchList.value[index][status] = 2;
					batchList.value[index][progress] = 100;
					resolve({
						success: true,
						data: res.data.data
					});
				} else {
					batchList.value[index][status] = 3;
					resolve({
						success: false,
						errorMsg: `status:${res.status},success:${res.data.success},description:${res.data.description}`
					});
				}
			})
			.catch(error => {
				batchList.value[index][status] = 3;
				resolve({
					success: false,
					errorMsg: JSON.stringify(error)
				});
			});
		})
	}

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, {
				type: 'array'
			});
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];
			let excelData = XLSX.utils.sheet_to_json(worksheet, {
				header: 1
			});
			let batchArr = [];
			excelData.forEach(item => {
				if (item[0] && item[1] && item[2]) {
					batchArr.push({
						"rootId": "0",
						"areaCode": item[0], //区号
						"phone": item[1] + "", //号
						"smsUrl": item[2], //号链
						"remark": item[3] || "", //备注
						"fansNum": 0,
						"status": 0
					})
				}
			})
			batchList.value = batchArr;

			dialogUpVisible.value = true;
		};
		reader.readAsArrayBuffer(file);
	}

	const clickBatch = () => {
		document.getElementById('inputF').click();
	}

	function extractNameAndId(str) {
		var numbers = str.match(/\d+/g) || []; // 提取数字
		var letters = str.match(/[a-zA-Z]+/g) || []; // 提取字母
		var chinese = str.match(/[\u4e00-\u9fa5]/g) || []; // 提取汉字
		return {
			idCard: numbers.join('') + letters.join(''),
			name: chinese.join('')
		};
	}

	const downloadTxtFile = (scope) => {
		let content = `${scope.row.areaCode}\r${scope.row.phone}\r${scope.row.account || '-'}\r${scope.row.registerTime?getRegistDate(scope.row.registerTime):'-'}\r${scope.row.smsUrl}`;
		const blob = new Blob([content], {
			type: 'text/plain'
		});
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = `${scope.row.areaCode} ${scope.row.phone}.txt`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	}

	const copyText = (scope) => {
		/* let text = `${scope.row.areaCode}\t${scope.row.phone}\t${scope.row.smsUrl}`;
		navigator.clipboard.writeText(text)
		.then(function() {
			ElMessage({
				message: '复制成功',
				type: 'success'
			})
		})
		.catch(function(error) {
			ElMessage({
				message: '失败:' + error,
				type: 'error'
			})
		}); */

		let text = `${scope.row.areaCode}\t${scope.row.phone}\t${scope.row.smsUrl}`;
		var textArea = document.createElement("textarea");
		textArea.value = text;

		// 防止元素显示在屏幕上
		textArea.style.position = "fixed";
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.width = "2em";
		textArea.style.height = "2em";
		textArea.style.padding = "0";
		textArea.style.border = "none";
		textArea.style.outline = "none";
		textArea.style.boxShadow = "none";
		textArea.style.background = "transparent";

		document.body.appendChild(textArea);
		textArea.select();

		try {
			var successful = document.execCommand('copy');
			var msg = successful ? 'successful' : 'unsuccessful';
			ElMessage({
				message: '复制成功',
				type: 'success'
			})
		} catch (err) {
			ElMessage({
				message: '失败:' + err,
				type: 'error'
			})
		}

		document.body.removeChild(textArea);
	}

	const downRow = (scope) => {
		downloadFile(scope.row.videoUrl, scope.row.name + scope.row.idCard)
	}

	function downloadFile(filePath, name) {
		const link = document.createElement('a')
		link.style.display = 'none'
		link.href = filePath
		link.setAttribute('download', name);
		link.download = name
		document.body.appendChild(link)
		link.click()
	}

	const pageTotal = ref(0)

	const uploaderVideo = ref(null);

	const uploaderImage = ref(null);

	const onSuccess = (res) => {
		if (res.success) {
			formData.value.videoUrl = 'https://file.douyin.bet/api/materials/download/' + res.data;
		} else {
			ElMessage({
				message: '上传失败',
				type: 'error'
			})
		}
	}

	const onSuccessImage = (res) => {
		if (res.success) {
			formData.value.imageUrl = 'https://file.douyin.bet/api/materials/download/' + res.data;
		} else {
			ElMessage({
				message: '上传失败',
				type: 'error'
			})
		}
	}

	const handleExceed = (files) => {
		uploaderVideo.value.clearFiles();
		const file = files[0];
		file.uid = genFileId();
		uploaderVideo.value.handleStart(file);
	}

	const handleExceedImage = (files) => {
		uploaderImage.value.clearFiles();
		const file = files[0];
		file.uid = genFileId();
		uploaderImage.value.handleStart(file);
	}

	const clickAdd = () => {
		resetFormData();
		dialogVisible.value = true;

	}

	const submitInfoForm = () => {
		let methodName = 'insertAccount';

		if (formData.value.accountId) {
			methodName = 'editAccount';
		}

		let data = {
			...formData.value
		};

		console.log(data.sellTime);

		if (data.sellTime && data.status == 1) {
			data.sellTime = new Date(data.sellTime).valueOf()
		}

		mainApi.admin[methodName]({
			...data
		}).then(res => {
			/* console.log(res); */
			getAccountPage();
			if (res.flag) {
				dialogVisible.value = false;
			} else {
				ElMessage({
					message: res.errorMsg
				})
			}
		})
	}

	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus';

	const handleCurrentChange = (e) => {
		pageData.pageNum = e;
		getAccountPage();
	}

	const handleSizeChange = (e) => {
		pageData.pageNum = 1;
		pageData.pageSize = e;
		getAccountPage();
	}

	const pageData = ref({
		"pageNum": 1,
		"pageSize": 20,
		/* accountStatus: null,
		status: null,
		type: null,
		loginType: null,
		loginFailStatus: null */
	})

	onMounted(() => {
		getAccountPage();
		uploaderData.value.token = localCache.getCache('ad-cache');
	})

	const matList = ref([]);

	const getAccountPage = () => {
		loading.value = true;
		mainApi.admin.getAccountPage({
			...pageData.value
		}).then(res => {
			console.log(res);
			loading.value = false;
			if (res.flag) {
				matList.value = res.data.pageInfo.list;
				pageTotal.value = res.data.pageInfo.total;
			} else {
				ElMessage({
					message: res.errorMsg,
					type: 'error'
				})
			}
		})
	}

	const editRow = (scope) => {
		console.log(scope.row);
		formData.value = {
			...scope.row
		};
		dialogVisible.value = true;
	}

	const removeRowUp = (scope) => {
		batchList.value.splice(scope.$index, 1)
	}

	const messageRow = (scope) => {
		console.log(scope.row.smsUrl);
		window.open(scope.row.smsUrl, '_blank');
	}

	const createQrcode = async (scope) => {
		if (!scope.row.qrUrl) {
			console.log(111);
			matList.value[scope.$index].qrUrl = await QRCode.toDataURL(JSON.stringify({
				accountId: scope.row.accountId
			}), {
				width: 120, // 二维码宽度
				margin: 0, // 外边距
			})
		}
	}

	const removeRow = (scope) => {
		ElMessageBox.confirm('是否删除?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			console.log(1);
			mainApi.admin.delAccount({
				accountId: scope.row.accountId
			}).then(res => {
				if (res.flag) {
					ElMessage({
						message: '已删除',
						type: 'success'
					})
				} else {
					ElMessage({
						message: res.errorMsg,
						type: 'error'
					})
				}
				getAccountPage()
			})
		}).catch(() => {});
	}

	const getRecentDate = (date) => {
		if (date) {
			return timestampToDateTime(date);
		} else {
			return '未使用'
		}
	}

	const getRegistDate = (date) => {
		if (date) {
			return timestampToDayTime(date);
		} else {
			return '-'
		}
	}

	const timestampToDateTime = (timestamp) => {
		const date = new Date(timestamp); // 将时间戳转换为Date对象
		const year = date.getFullYear(); // 获取年份
		const month = ('0' + (date.getMonth() + 1)).slice(-2); // 获取月份，并且保证两位数字
		const day = ('0' + date.getDate()).slice(-2); // 获取日期，并且保证两位数字
		const hours = ('0' + date.getHours()).slice(-2); // 获取小时，并且保证两位数字
		const minutes = ('0' + date.getMinutes()).slice(-2); // 获取分钟，并且保证两位数字
		const seconds = ('0' + date.getSeconds()).slice(-2); // 获取秒钟，并且保证两位数字
		return `${month}-${day} ${hours}:${minutes}`; // 拼接字符串返回
	}

	const timestampToDayTime = (timestamp) => {
		const date = new Date(timestamp); // 将时间戳转换为Date对象
		const year = date.getFullYear(); // 获取年份
		const month = ('0' + (date.getMonth() + 1)).slice(-2); // 获取月份，并且保证两位数字
		const day = ('0' + date.getDate()).slice(-2); // 获取日期，并且保证两位数字
		return `${year}-${month}-${day}`; // 拼接字符串返回
	}
</script>

<style lang="scss">
	.mat-main {
		height: 100vh;
		padding: 30px 35px;
	}

	.mat-content {
		display: flex;
		flex-direction: column;

		.title-area {
			padding: 20px 0;
		}

		.table-area {
			flex: 1;
			height: 0;
		}
	}

	.qrArea {
		width: 120px;
		height: 120px;
	}
</style>