<template>
	<div class="">
		<div class="mat-main w100 bsbb">
			<div class="wh100 mat-content">
				<div class="title-area w100 hc">
					<el-button type="primary" round @click="clickBatch">批量</el-button>
					<input style="width: 15px;opacity: 0;" ref="inputFile" id="inputF" type="file" @change="handleFileChange" webkitdirectory directory multiple>
					<el-input @keyup.enter="searchMat" v-model="pageData.checkNumMin" style="width: 100px;" placeholder="通过数"></el-input>
					<div style="padding: 0 5px;color: #999;">~</div>
					<el-input @keyup.enter="searchMat" v-model="pageData.checkNumMax" style="width: 100px;" placeholder="通过数"></el-input>
					<el-input @keyup.enter="searchMat" v-model="pageData.notCheckNumMin" style="width: 100px;margin-left: 15px;" placeholder="失败数"></el-input>
					<div style="padding: 0 5px;color: #999;">~</div>
					<el-input @keyup.enter="searchMat" v-model="pageData.notCheckNumMax" style="width: 100px;" placeholder="失败数"></el-input>
					<el-select @change="searchMat" v-model="pageData.sortType" style="width: 240px;margin-left: 15px;">
						<el-option :label="item.label" :value="item.value" v-for="(item,index) in sortArr" :key="index"></el-option>
					</el-select>
					<div style="flex:1">
					</div>
					<el-button type="primary" round @click="clickAdd">新建</el-button>
				</div>
				<div class="table-area w100">
					<el-table v-loading="loading" :data="matList" style="height: 100%;" empty-text="无">
						<el-table-column type="selection" width="40" />
						<el-table-column type="index" label="序号" width="80" />
						<el-table-column prop="name" label="名称" min-width="150" />
						<el-table-column prop="idCard" label="号" min-width="240" />
						<el-table-column prop="checkNum" label="使用次数" min-width="120">
							<template #default="scope">
								<div>
									{{scope.row.notCheckNum * 1 + scope.row.checkNum *1}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="checkNum" label="通过次数" min-width="120" />
						<el-table-column prop="notCheckNum" label="失败次数" min-width="120" />
						<el-table-column prop="notCheckNum" label="最近使用时间" min-width="150">
							<template #default="scope">
								<div>
									{{getRecentDate(scope.row.lastUseTime)}}
								</div>
							</template>
						</el-table-column>
						<!-- <el-table-column prop="idCard" label="视频" width="80">
							<template #default="scope">
								<div>
									<el-button type="primary" @click="downRow(scope)" link v-if="scope.row.videoUrl && scope.row.videoUrl.indexOf('http') != -1">下载</el-button>
									<div v-if="scope.row.videoUrl && scope.row.videoUrl.indexOf('http') == -1">加载中</div>
								</div>
							</template>
						</el-table-column> -->
						<el-table-column prop="idCard" label="原图" width="80">
							<template #default="scope">
								<el-image preview-teleported :preview-src-list="[replaceUrl(scope.row.imageUrl)]" style="width: 50px;height: 80px;border-radius: 2px;" fit="cover" :src="replaceUrl(scope.row.imageUrl)"></el-image>
							</template>
						</el-table-column>
						<el-table-column prop="idCard" label="高清" width="80">
							<template #default="scope">
								<el-tag type="info" v-if="scope.row.highType == 0">
									否
								</el-tag>
								<el-tag type="primary" v-if="scope.row.highType == 1">
									是
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="180">
							<template #default="scope">
								<el-button size="small" @click="editRow(scope)" style="border-radius: 5px;border:none;" type="primary" :icon="'EditPen'" circle plain></el-button>
								<el-button size="small" @click="removeRow(scope)" style="border-radius: 5px;border:none;" type="primary" :icon="'Delete'" circle plain></el-button>
								<!-- <el-button link @click="editRow(scope)" type="primary">编辑</el-button>
								<el-button link @click="removeRow(scope)" type="primary">移除</el-button> -->
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="jc w100" style="height: 50px;">
					<el-pagination v-model:current-page="pageData.pageNum" v-model:page-size="pageData.pageSize" :page-sizes="[20,50,100,200,1000]" :small="false" :background="false" layout="sizes, prev, pager, next, jumper" :total="pageTotal" @size-change="handleSizeChange"
						@current-change="handleCurrentChange" />
				</div>
			</div>
		</div>

		<el-dialog align-center v-model="dialogVisible" :title="'编辑'" width="500px">
			<div class="form-area w100 bsbb" style="padding: 0 20px;">
				<el-form class="el-form w100" style="" require-asterisk-position="left" ref="formRef" :model="formData">
					<el-form-item prop="rootId" :label-width="'100px'" class="user-form-item" label="rootId">
						<el-input maxlength="10" v-model="formData.rootId" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="nickname" :label-width="'100px'" class="user-form-item" label="名称">
						<el-input maxlength="20" v-model="formData.name" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="mobile" :label-width="'100px'" class="user-form-item" label="号码">
						<el-input maxlength="30" v-model="formData.idCard" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="mobile" :label-width="'100px'" class="user-form-item" label="视频">
						<el-upload :data="uploaderData" accept=".mp4,.mov" ref="uploaderVideo" class="upload-demo w100" action="http://192.69.95.159:9287/api/materials/upload" :on-success="onSuccess" :limit="1" :on-exceed="handleExceed" :auto-upload="true">
							<template #trigger>
								<el-button type="primary">选择视频</el-button>
							</template>
						</el-upload>
						<div v-if="formData.videoUrl" class="wh100 jc fl" style="height: 200px;">
							<video-player v-if="formData.videoUrl" class=" fl" style="width: 330px;height: 200px;border-radius: 20px;" :src="replaceUrl(formData.videoUrl)" :controls="true" />
						</div>
					</el-form-item>
					<el-form-item prop="mobile" :label-width="'100px'" class="user-form-item" label="原图">
						<el-upload :data="uploaderData" accept=".jpg,.png,.jpeg" ref="uploaderImage" class="upload-demo fl w100" action="http://192.69.95.159:9287/api/materials/upload" :on-success="onSuccessImage" :limit="1" :on-exceed="handleExceedImage" :auto-upload="true">
							<template #trigger>
								<el-button type="primary">选择图片</el-button>
							</template>
						</el-upload>
						<div v-if="formData.imageUrl" class="wh100 jc fl" style="height: 80px;">
							<el-image fit="cover" style="width: 80px;height: 80px;border-radius: 10px;" :src="formData.imageUrl"></el-image>
						</div>
					</el-form-item>
					<el-form-item prop="email" :label-width="'100px'" class="user-form-item" label="高清">
						<el-select class="" style="width: 100%;" v-model="formData.highType">
							<el-option label="是" :value="1"></el-option>
							<el-option label="否" :value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="submitInfoForm">确认</el-button>
				</div>
			</template>
		</el-dialog>


		<el-dialog align-center v-model="dialogUpVisible" :title="'批量'" width="80%">
			<div class="form-area w100 bsbb" style="padding: 0 20px;">
				<el-table :data="batchList" style="height: 60vh;" empty-text="无">
					<el-table-column type="selection" width="40" />
					<el-table-column type="index" label="序号" width="80" />
					<el-table-column prop="name" label="root" min-width="80">
						<template #default="scope">
							<el-input v-model="scope.row.rootId"></el-input>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="名称" min-width="150">
						<template #default="scope">
							<el-input v-model="scope.row.name"></el-input>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="ID" min-width="240">
						<template #default="scope">
							<el-input v-model="scope.row.idCard"></el-input>
						</template>
					</el-table-column>

					<el-table-column prop="industry" label="图片状态" width="120">
						<template #default="scope">
							<el-tag v-if="scope.row.imageStatus == 0" type="info">未开始</el-tag>
							<el-tag v-if="scope.row.imageStatus == 1" type="warning">上传中</el-tag>
							<el-tag v-if="scope.row.imageStatus == 2" type="success">已完成</el-tag>
							<el-tag v-if="scope.row.imageStatus == 3" type="danger">异常</el-tag>
						</template>
					</el-table-column>

					<el-table-column prop="industry" label="图片进度" width="150">
						<template #default="scope">
							<el-progress :percentage="scope.row.imageProgress" :color="customColors" />
						</template>
					</el-table-column>

					<el-table-column prop="industry" label="视频状态" width="120">
						<template #default="scope">
							<el-tag v-if="scope.row.videoStatus == 0" type="info">未开始</el-tag>
							<el-tag v-if="scope.row.videoStatus == 1" type="warning">上传中</el-tag>
							<el-tag v-if="scope.row.videoStatus == 2" type="success">已完成</el-tag>
							<el-tag v-if="scope.row.videoStatus == 3" type="danger">异常</el-tag>
						</template>
					</el-table-column>

					<el-table-column prop="industry" label="视频进度" width="150">
						<template #default="scope">
							<el-progress :percentage="scope.row.videoProgress" :color="customColors" />
						</template>
					</el-table-column>

					<el-table-column prop="industry" label="保存状态" width="120">
						<template #default="scope">
							<el-tag v-if="scope.row.saveStatus == 0" type="info">未开始</el-tag>
							<el-tag v-if="scope.row.saveStatus == 1" type="warning">保存中</el-tag>
							<el-tag v-if="scope.row.saveStatus == 2" type="success">已完成</el-tag>
							<el-tag v-if="scope.row.saveStatus == 3" type="danger">异常</el-tag>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="80">
						<template #default="scope">
							<el-button size="small" @click="removeRowUp(scope)" style="border-radius: 5px;border:none;" type="primary" :icon="'Delete'" circle plain></el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogUpVisible = false">取消</el-button>
					<el-button type="primary" :loading="uploading" @click="submitUpForm">开始</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		inject,
		onMounted
	} from 'vue';

	const axios = require('axios');

	const downUrl = 'http://192.69.95.159:9287/api/materials/download/'

	import localCache from '../../libs/utils/localCache.js';

	const mainApi = inject('mainRequest');

	const inputFile = ref(null);

	setTimeout(() => {
		console.log(inputFile)
	}, 1000)

	const uploaderData = ref({
		token: ''
	})

	const dialogUpVisible = ref(false);

	const dialogVisible = ref(false);

	const loading = ref(false);

	const customColors = ref([{
			color: '#f56c6c',
			percentage: 20
		},
		{
			color: '#e6a23c',
			percentage: 40
		},
		{
			color: '#6f7ad3',
			percentage: 60
		},
		{
			color: '#1989fa',
			percentage: 80
		},
		{
			color: '#5cb87a',
			percentage: 100
		}
	]);

	const replaceUrl = (url) => {
		return url.replace('https://file.douyin.bet', 'http://192.69.95.159:9287')
	}

	const formData = ref({
		"rootId": "",
		"name": "",
		"idCard": "",
		"videoUrl": "",
		"imageUrl": "",
		"highType": 0
	})

	const searchMat = () => {
		pageData.value.pageNum = 1;
		getMatPage()
	}

	const uploading = ref(false);

	const sortArr = ref([{
		label: '创建时间正序',
		value: 1
	}, {
		label: '创建时间倒叙',
		value: 2
	}, {
		label: '最后使用时间正序',
		value: 3
	}, {
		label: '最后使用时间倒叙',
		value: 4
	}, {
		label: '通过次数正序',
		value: 5
	}, {
		label: '通过次数倒叙',
		value: 6
	}, {
		label: '未通过次数正序',
		value: 7
	}, {
		label: '未通过次数倒叙',
		value: 8
	}, {
		label: '最后修改时间正序',
		value: 9
	}, {
		label: '最后修改时间倒叙',
		value: 0
	}])

	const resetFormData = () => {
		formData.value = {
			"rootId": "",
			"name": "",
			"idCard": "",
			"videoUrl": "",
			"imageUrl": "",
			"highType": 0
		};
	}

	const batchList = ref([]);

	const submitUpForm = async () => {
		if (!batchList.value.length) {
			return;
		}
		uploading.value = true;
		for (let i in batchList.value) {
			console.log(batchList.value[i]);
			const {
				imageFile,
				videoFile,
				rootId,
				highType,
				name,
				idCard
			} = batchList.value[i];
			let data = {}
			let flag = true;
			if (imageFile) {
				let res = await upItem(batchList.value[i].imageFile, i, 'imageStatus', 'imageProgress');
				console.log(res);
				if (res.success) {
					data.imageUrl = downUrl + res.data;
				} else {
					flag = false;
				}
			}
			if (videoFile) {
				let res = await upItem(batchList.value[i].videoFile, i, 'videoStatus', 'videoProgress');
				console.log(res);
				if (res.success) {
					data.videoUrl = downUrl + res.data;
				} else {
					flag = false;
				}
			}

			if (flag) {
				await asyncSaveMat({
					...data,
					rootId,
					highType,
					name,
					idCard
				}, i)
			}
		}
		uploading.value = false;
		ElMessage({
			message: '执行结束',
			type: 'success'
		})
		getMatPage();
	}

	const asyncSaveMat = async (data, index) => {
		batchList.value[index].saveStatus = 1;
		return new Promise((resolve, reject) => {
			mainApi.admin.insertMaterial({
				...data
			}).then(res => {
				if (res.flag) {
					batchList.value[index].saveStatus = 2;
				} else {
					batchList.value[index].saveStatus = 3;
					batchList.value[index].errorMsg = res.errorMsg;
				}
				resolve();
			})
		})
	}

	const upItem = async (file, index, status, progress) => {
		batchList.value[index][status] = 1;
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			// 通过文件路径读取文件，并添加到FormData中
			formData.append('file', file);
			// 设置请求头，告诉服务器这是一个表单数据
			axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
			// 发送POST请求
			axios.post('http://192.69.95.159:9287/api/materials/upload', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: progressEvent => {
						// 获取上传进度，progressEvent.loaded是已上传的数据，progressEvent.total是总数据量
						const uploadPercent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
						batchList.value[index][progress] = uploadPercent;
					},
				})
			.then(res => {
				console.log(res.status, res.data.success)
				if (res.status == 200 && res.data.success == true) {
					batchList.value[index][status] = 2;
					batchList.value[index][progress] = 100;
					resolve({
						success: true,
						data: res.data.data
					});
				} else {
					batchList.value[index][status] = 3;
					resolve({
						success: false,
						errorMsg: `status:${res.status},success:${res.data.success},description:${res.data.description}`
					});
				}
			})
			.catch(error => {
				batchList.value[index][status] = 3;
				resolve({
					success: false,
					errorMsg: JSON.stringify(error)
				});
			});
		})
	}

	const handleFileChange = (e) => {
		console.log(e.target.files);
		let map = {};
		for (let i in e.target.files) {
			let file = e.target.files[i];
			if (i != 'length' && (file.name.indexOf('.mp4') != -1 || file.name == '1.png')) {
				let dirName = e.target.files[i].webkitRelativePath.split('/')[1];
				const {
					idCard,
					name
				} = extractNameAndId(dirName);

				if (!map[dirName]) {
					map[dirName] = {
						rootId: '0',
						idCard,
						name,
						imageProgress: 0,
						imageStatus: 0, // 0 未开始 1下载中  2已完成 3出错了
						videoProgress: 0,
						videoStatus: 0,
						imageUrl: '',
						videoUrl: '',
						highType: 0,
						saveStatus: 0,
						errorMsg: ''
					};
				}
				let type = "imageFile";
				if (file.name.indexOf('.mp4') != -1) { //视频
					type = "videoFile"
				}
				map[dirName][type] = file;
			}
			/* if (i == 0) {
				let dirName = e.target.files[i].webkitRelativePath.split('/')[1];
				console.log(dirName);
				console.log(extractNameAndId(dirName));
				const {
					idCard,
					name
				} = extractNameAndId(dirName);
				if()
			} */
		}

		console.log(map);
		let arr = [];
		for (let key in map) {
			console.log(map[key]);
			arr.push(map[key]);
		}
		console.log(arr);
		if (arr.length) {
			batchList.value = arr;
			dialogUpVisible.value = true;
		}
	}

	const clickBatch = () => {
		document.getElementById('inputF').click();
	}

	function extractNameAndId(str) {
		var numbers = str.match(/\d+/g) || []; // 提取数字
		var letters = str.match(/[a-zA-Z]+/g) || []; // 提取字母
		var chinese = str.match(/[\u4e00-\u9fa5]/g) || []; // 提取汉字
		return {
			idCard: numbers.join('') + letters.join(''),
			name: chinese.join('')
		};
	}

	const downRow = (scope) => {
		downloadFile(scope.row.videoUrl, scope.row.name + scope.row.idCard)
	}

	function downloadFile(filePath, name) {
		const link = document.createElement('a')
		link.style.display = 'none'
		link.href = filePath
		link.setAttribute('download', name);
		link.download = name
		document.body.appendChild(link)
		link.click()
	}

	const pageTotal = ref(0)

	const uploaderVideo = ref(null);

	const uploaderImage = ref(null);

	const onSuccess = (res) => {
		if (res.success) {
			formData.value.videoUrl = 'http://192.69.95.159:9287/api/materials/download/' + res.data;
		} else {
			ElMessage({
				message: '上传失败',
				type: 'error'
			})
		}
	}

	const onSuccessImage = (res) => {
		if (res.success) {
			formData.value.imageUrl = 'http://192.69.95.159:9287/api/materials/download/' + res.data;
		} else {
			ElMessage({
				message: '上传失败',
				type: 'error'
			})
		}
	}

	const handleExceed = (files) => {
		uploaderVideo.value.clearFiles();
		const file = files[0];
		file.uid = genFileId();
		uploaderVideo.value.handleStart(file);
	}

	const handleExceedImage = (files) => {
		uploaderImage.value.clearFiles();
		const file = files[0];
		file.uid = genFileId();
		uploaderImage.value.handleStart(file);
	}

	const clickAdd = () => {
		resetFormData();
		dialogVisible.value = true;

	}

	const submitInfoForm = () => {
		if (!formData.value.rootId) {
			ElMessage({
				message: '请输入rootId'
			})
			return;
		}

		if (!formData.value.name) {
			ElMessage({
				message: '请输入名称'
			})
			return;
		}

		if (!formData.value.idCard) {
			ElMessage({
				message: '请输入号码'
			})
			return;
		}

		if (!formData.value.imageUrl) {
			ElMessage({
				message: '请选择图片'
			})
			return;
		}

		let methodName = 'insertMaterial';

		if (formData.value.materialId) {
			methodName = 'editMaterial';
		}

		mainApi.admin[methodName]({
			...formData.value
		}).then(res => {
			/* console.log(res); */
			getMatPage();
			if (res.flag) {
				dialogVisible.value = false;
			} else {
				ElMessage({
					message: res.errorMsg
				})
			}
		})
	}

	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus';

	const handleCurrentChange = (e) => {
		pageData.pageNum = e;
		getMatPage();
	}

	const handleSizeChange = (e) => {
		pageData.pageNum = 1;
		pageData.pageSize = e;
		getMatPage();
	}

	const pageData = ref({
		"checkNumMax": '', //通过次数max
		"sortType": 0,
		"checkNumMin": '', //通过次数min
		"notCheckNumMax": '', //不通过次数max
		"notCheckNumMin": '', //不通过次数min
		"pageNum": 1,
		"pageSize": 20
	})

	onMounted(() => {
		getMatPage();
		uploaderData.value.token = localCache.getCache('ad-cache');
	})

	const matList = ref([]);

	const getMatPage = () => {
		loading.value = true;
		mainApi.admin.getMaterialPage({
			...pageData.value
		}).then(res => {
			console.log(res);
			loading.value = false;
			if (res.flag) {
				matList.value = res.data.pageInfo.list;
				pageTotal.value = res.data.pageInfo.total;
			} else {
				ElMessage({
					message: res.errorMsg,
					type: 'error'
				})
			}
		})
	}

	const editRow = (scope) => {

		formData.value = {
			...scope.row
		};
		dialogVisible.value = true;
	}

	const removeRowUp = (scope) => {
		batchList.value.splice(scope.$index, 1)
	}

	const removeRow = (scope) => {
		console.log(scope.row.materialId);
		ElMessageBox.confirm('是否删除?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			console.log(1);
			mainApi.admin.delMaterial({
				ids: [scope.row.materialId]
			}).then(res => {
				if (res.flag) {
					ElMessage({
						message: '已删除',
						type: 'success'
					})
				} else {
					ElMessage({
						message: res.errorMsg,
						type: 'error'
					})
				}
				getMatPage()
			})
		}).catch(() => {});
	}

	const getRecentDate = (date) => {
		if (date) {
			return timestampToDateTime(date);
		} else {
			return '未使用'
		}
	}

	const timestampToDateTime = (timestamp) => {
		const date = new Date(timestamp); // 将时间戳转换为Date对象
		const year = date.getFullYear(); // 获取年份
		const month = ('0' + (date.getMonth() + 1)).slice(-2); // 获取月份，并且保证两位数字
		const day = ('0' + date.getDate()).slice(-2); // 获取日期，并且保证两位数字
		const hours = ('0' + date.getHours()).slice(-2); // 获取小时，并且保证两位数字
		const minutes = ('0' + date.getMinutes()).slice(-2); // 获取分钟，并且保证两位数字
		const seconds = ('0' + date.getSeconds()).slice(-2); // 获取秒钟，并且保证两位数字
		return `${month}-${day} ${hours}:${minutes}`; // 拼接字符串返回
	}
</script>

<style lang="scss">
	.mat-main {
		height: 100vh;
		padding: 30px 35px;
	}

	.mat-content {
		display: flex;
		flex-direction: column;

		.title-area {
			padding: 20px 0;
		}

		.table-area {
			flex: 1;
			height: 0;
		}
	}
</style>