<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {}
	}
</script>

<style>
	@import url("assets/css/common.scss");
	@import url("assets/css/wtheme.scss");

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	.video-js .vjs-big-play-button {
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
	}
</style>